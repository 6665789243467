require('../common');

/////////////////////////
// style
require('../../../vendor/bower-asset/bootstrap/dist/css/bootstrap.css');

/////////////////////////
// emoji
require('../lib/emoji/emoji2cp');
require('../lib/emoji/jquery.emojify');
require('../lib/emoji/twemoji-awesome.css');
// 絵文字の定義に外部リンクも必要

// 要 FontAwesome リンク

import './site/app.css';

// 必要なら mdtoolbox もバンドルするべし
